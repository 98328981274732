import { Injectable, Inject } from '@angular/core';
import {BasicModuleService} from '../basic-module.service';
import {Tk3dService} from '../../tk3d.service';
import {ALL_VIEW3D_EVENTS} from './view3deventsarray-helper.provider';
import {InteractionMessageProtocol, ModuleSize, TK3dElement, Tk3dEvents} from '../../definitions';

@Injectable()
export class View3dService extends BasicModuleService {

  private panoSelected: string;
  constructor(tk3d: Tk3dService, @Inject(ALL_VIEW3D_EVENTS) private events) {
    super(tk3d);
    for (const event of this.events) {
      this.callbacks[event] = this.constructCallbackBinded(event);
    }
    this.selector = 'view3d';
    this.miniSelector = this.selector + '-mini';
  }

  init (emitters) {
    this.emitters = emitters;
    const config = {
      module: 'view3d',
      container: this.selector,
      config: {
        callbacks: this.callbacks
      }
    };
    this.moduleRef = this.tk3d.loadModule(config, 'view3d');
    window['view3d'] = this.moduleRef;
  }

  loadElement(panoID) {
    this.panoSelected = panoID;
    this.moduleRef.load(panoID, this.callbacks['loaded']);
  }

  protected constructMessage(event: Tk3dEvents, extra: TK3dElement | boolean | string | undefined): InteractionMessageProtocol {
    const message: InteractionMessageProtocol = {
      event: event,
      emitter: 'view3d',
      emmitterSize: this.size,
      elementSelected: this.panoSelected,
    };
    return message;
  }

}
